<template>
  <div style="display: flex">
    <!-- card actions -->
    <b-col cols="7">
      <b-col cols="12" v-if="projectData">
        <div v-for="(item, index) in items" :key="index">
          <b-form-group
            v-if="urlsForExternal(item)"
            :label="item.title"
            :label-for="`url-${index}`"
            label-cols-md="4"
          >
            <div
              v-clipboard:copy="item.value"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            >
              <b-form-input
                v-if="[0, 1, 2, 4, 5, 6].includes(index)"
                style="cursor: pointer"
                v-model="item.value"
                :id="`url-${index}`"
              />

              <b-form-textarea
                v-if="[3, 7].includes(index)"
                style="cursor: pointer"
                id="textarea"
                v-model="item.value"
                rows="3"
                max-rows="6"
                spellcheck='false'
              ></b-form-textarea>
            </div>
          </b-form-group>
          <q-page
            v-if="item.title == 'Staging url' || item.title == 'Production url'"
          >
            <b-form-group
              v-if="item.title == 'Staging url'"
              label="Custom Staging url"
              label-cols-md="4"
              label-for="staging-url-input mt-4"
            >
              <b-form-input
                id="staging-url-input"
                v-model="customStagingURL"
                placeholder="Enter staging url"
                class="short-input"
              >
              </b-form-input>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="onClickButtonAsync('staging')"
                :disabled="busy"
                class="url-btn"
               >
                Submit
              </b-button>
            </b-form-group>
            <b-form-group
              v-if="item.title == 'Production url'"
              label="Custom Production url"
              label-cols-lg="4"
              label-for="production-url-input"
            >
              <b-form-input
                id="production-url-input"
                v-model="customProductionURL"
                placeholder="Enter production url"
                class="short-input"
              >
              </b-form-input>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="onClickButtonAsync('production')"
                :disabled="busy"
              >
                Submit
              </b-button>
            </b-form-group>
            <qrcode
              :value="getURL(item)"
              :ref="item.title + '-qr'"
              :options="{ width: 200 }"
            ></qrcode>
            <div class="qrcode-btn">
              <b-button class="btn-save" @click="exportPDF(item.title)">
                <span>Save PDF</span>
              </b-button>
              <b-button class="btn-save" @click="exportPNG(item.title)">
                <span>Save Picture</span>
              </b-button>
            </div>
          </q-page>
        </div>
      </b-col>
    </b-col>

    <b-col
      cols="5"
      style="display: flex; flex-direction: column; align-items: center"
    >
      <b-tabs v-if="getProductionURL">
        <b-tab
          title="Staging preview"
          @click="showActiveIframe('staging')"
        ></b-tab>
        <b-tab
          title="Production preview"
          active
          @click="showActiveIframe('production')"
        ></b-tab>
        <b-card-text
          style="text-align: center; margin: 20px auto 0"
          v-if="activeIframeIsStaging"
        >
          <iframe allowfullscreen="true" :src="getStagingURL + '?isHosting=true'" title=""></iframe>
        </b-card-text>
        <b-card-text
          style="text-align: center; margin: 20px auto 0"
          v-if="activeIframeIsProduction"
        >
          <iframe allowfullscreen="true" :src="getProductionURL + '?isHosting=true'" title=""></iframe>
        </b-card-text>
        <b-card-text
          style="text-align: center; margin: 20px auto 0"
          v-if="!activeIframeIsProduction && !activeIframeIsStaging"
        >
          Chose some Custom URL for External Project please!
        </b-card-text>
      </b-tabs>
    </b-col>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCardText,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import Jspdf from "jspdf";
import Vue from "vue";
import { errorMessage } from "@/auth/utils";

export default {
  components: {
    BTabs,
    BTab,
    BCol,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    VueQrcode,
    Jspdf,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      projectData: {},
      activeIframe: "production",
      counter: 1,
      processing: false,
      busy: false,
      customStagingURL: null, 
      customProductionURL: null,
    };
  },
  computed: {
    activeIframeIsStaging() {
      // Don't show Iframe if External Project without Custom URL
      if (!(this.projectData.project_type === 'external')) {
        return this.activeIframe === 'staging'
      } else if (this.customStagingURL) {
        return this.activeIframe === 'staging'
      } else {
        return false
      }
    },
    activeIframeIsProduction() {
      // Don't show Iframe if External Project without Custom URL
      if (!(this.projectData.project_type === 'external')) {
        return this.activeIframe === 'production'
      } else if (this.customProductionURL) {
        return this.activeIframe === 'production'
      } else {
        return false
      }
    },
    getStagingURL() {
      return  this.customStagingURL ? this.customStagingURL : this.projectData.staging_url 
    },
    getProductionURL() {
      return  this.customProductionURL ? this.customProductionURL : this.projectData.production_url 
    },
    items() {
      return [
        { title: "Staging url", value: this.projectData.staging_url },
        { title: "Staging decrypted url", value: this.projectData.staging_decrypted_url },
        {
          title: "Staging iframe",
          value: `<iframe allowfullscreen="true" src=${this.projectData.staging_url}></iframe>`,
        },
        {
          title: "Staging package",
          value: `import ModelViewer from 'revolva'
const viewerId = 'your DIV ID';
const projectId =<Project_UUID>;
new ModelViewer (viewerId, projectId, 'staging');`,
        },

        { title: "Production url", value: this.projectData.production_url },
        { title: "Production decrypted url", value: this.projectData.production_decrypted_url },
        {
          title: "Production iframe",
          value: `<iframe allowfullscreen="true" src=${this.projectData.production_url}></iframe>`,
        },
        {
          title: "Production package",
          value: `import ModelViewer from 'revolva'
const viewerId = 'your DIV ID';
const projectId =<Project_UUID>;
new ModelViewer (viewerId, projectId, 'production');`,
        },
      ];
    },
  },
  mounted() {
    Vue.component(VueQrcode.name, VueQrcode);

    store
      .dispatch("Project/loadProject", store.state.Project.project.id)
      .then((response) => {
        this.projectData = response.data;
        this.loadProjectAsync();
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.projectData = undefined;
        }
      });
  },
  methods: {
    urlsForExternal(item) {
      if (this.projectData.project_type === 'internal') {
        return true
      } else {
        return false
      }
       
    },
    async onClickButtonAsync(urlType) {
      this.busy = true;
      this.processing = true;
      this.counter = 1;

      await this.updateProjectAsync(urlType)
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title:
              "You have successfully updated the Project",
            icon: "EditIcon",
            variant: "success",
          },
        });
      })

      this.updateProcessing();
},

    async updateProjectAsync(urlType) {
      let patchData = {}
      if (urlType == 'staging') {
        patchData['custom_staging_url'] = this.customStagingURL
      } else if (urlType == 'production') {
        patchData['custom_production_url'] = this.customProductionURL
      }
      store.dispatch("Project/patchProject", {
        patchData: patchData,
        projectId: this.projectData.id
      })
      .catch((error) => {
          errorMessage(this.$toast, error);
        });
    },

    async loadProjectAsync() {
      store.dispatch("Project/loadProject", this.projectData.id)
        .then((res) => {
          this.customStagingURL = res.data.custom_staging_url;
          this.customProductionURL = res.data.custom_production_url;
        });
    },

    updateProcessing() {
      // Counter is going up from 1 to 5
      // Simulate an async request
      this.clearInterval();
      this.interval = setInterval(() => {
        if (this.counter < 5) {
          this.counter += 1;
        } else {
          this.clearInterval();
          this.$nextTick(() => {
            // eslint-disable-next-line
            this.busy = this.processing = false;
          });
        }
      }, 350);
    },

    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },

    exportPNG(title) {
      const contentHtml = this.$refs[title + "-qr"][0].$el;
      const image = contentHtml.toDataURL("image/jpeg", 0.8); // Base64 image
      const link = document.createElement("a");

      document.body.appendChild(link);
      link.setAttribute("href", image);
      link.setAttribute("download", "ProjectQRCode_3Digital.jpg");
      link.click();
    },
    exportPDF(title) {
      const doc = new Jspdf();
      const contentHtml = this.$refs[title + "-qr"][0].$el;
      const image = contentHtml.toDataURL("image/jpeg", 0.8);
      doc.addImage(image, "JPEG", 20, 20);
      doc.save("ProjectQRCode_3Digital.pdf");
    },

    getURL(item) {
      let url;
      if (item.title == "Staging url") {
        if (this.projectData.project_type === 'internal') {
          url = this.customStagingURL ? this.customStagingURL : item.value;
        } else { url = this.customStagingURL}
      } else if (item.title == "Production url") {
        if (this.projectData.project_type === 'internal') {
        url = this.customProductionURL ? this.customProductionURL : item.value;
        } else { url = this.customProductionURL}
      };
      return url;
    },

    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Text copied",
          icon: "BellIcon",
        },
      });
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Failed to copy texts!",
          icon: "BellIcon",
        },
      });
    },
    showActiveIframe(val) {
      this.activeIframe = val;
    },
  },
};
</script>

<style lang="scss">
.qrcode-btn {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 200px;
}
.qrcode-btn .btn-save {
  margin: 0 5px 5px 5px;
  padding: 4px 0px 4px 0px;
  background-color: black;
}
.heading-elements {
  display: none !important;
}
.short-input {
  width: 70%;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}
.url-btn {
  margin-bottom: 3px;
}
iframe {
  border: none !important;
  width: 100%;
  height: 600px;
}
.tabs {
  width: 100% !important;
}
.nav-tabs {
  display: flex;
  justify-content: center;
}
.dark-color {
  color: #b4b7bd;
}
.dark-layout .nav-tabs .nav-item .nav-link.active {
  color: #d0d2d6 !important;
}
td {
  position: relative !important;
  padding: 1.4rem 2rem !important;
}
.button-copy-clipboard {
  position: absolute;
  top: 0;
  right: 0;
  width: 42px;
  height: 18px;
  font-size: 9px;
  white-space: nowrap;
  padding: 0 !important;
  line-height: 18px;
}
#model-viewer-production,
#model-viewer-staging {
  canvas {
    width: 100% !important;
    height: 500px !important;
    outline: none;
    object-fit: cover;
  }
}
</style>
